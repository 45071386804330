import { ContactType } from '@condo/hubspot/types';

export const email = {
    service: 'service@condo.de',
    dataprotection: 'datenschutz@condo.de',
    info: 'info@condo.de',
    warranty: 'gewaehrleistung@condo.de',
};

// read from cms, this is just to keep a copy of the data
export const contactFormLists = {
    salutations: [
        {
            key: 'man',
            value: 'Herr',
        },
        {
            key: 'woman',
            value: 'Frau',
        },
        {
            key: 'company',
            value: 'Firma',
        },
    ],
    customerType: [
        {
            key: ContactType.Investor,
            value: 'Eigentümer',
        },
        {
            key: ContactType.WEG,
            value: 'WEG Verwaltung',
        },
        {
            key: ContactType.ServiceProvider,
            value: 'Versorger',
        },
        {
            key: ContactType.Craftsman,
            value: 'Handwerker',
        },
        {
            key: ContactType.Other,
            value: 'Sonstige',
        },
    ],
    categories: [
        {
            key: 'reparatur_wohnung',
            value: 'Reparatur Wohnung',
            subValues: [
                {
                    key: 'einrichtung',
                    value: 'Einrichtung',
                },
                {
                    key: 'wasser/heizung/sanitär',
                    value: 'Wasser/ Heizung/ Sanitär',
                },
                {
                    key: 'elektro',
                    value: 'Elektro',
                },
                {
                    key: 'internet/kabelanschluss/tv',
                    value: 'Internet/ Kabelanschluss/ TV',
                },
                {
                    key: 'feuchtigkeit/schimmel',
                    value: 'Feuchtigkeit/ Schimmel',
                },
                {
                    key: 'malerarbeiten/wände',
                    value: 'Malerarbeiten/ Wände',
                },
                {
                    key: 'rauchwarnmelder/heizkostenverteiler',
                    value: 'Rauchwarnmelder/ Heizkostenverteiler',
                },
                {
                    key: 'klingelanlage/briefkasten',
                    value: 'Klingelanlage/Briefkasten',
                },
                {
                    key: 'türen/fenster ',
                    value: 'Türen/ Fenster',
                },
                {
                    key: 'bauabnahme',
                    value: 'Bauabnahme',
                    limitedTo: ['investor'],
                },
                {
                    key: 'sonstiges',
                    value: 'Sonstiges',
                },
            ],
        },
        {
            key: 'frage_zur_mietsache',
            value: 'Frage zur Mietsache',
            subValues: [
                {
                    key: 'wohnungsübergabe',
                    value: 'Wohnungsübergabe',
                },
                {
                    key: 'schlüssel',
                    value: 'Schlüssel',
                },
                {
                    key: 'zählerstände',
                    value: 'Zählerstände',
                },
                {
                    key: 'balkon/terrasse',
                    value: 'Balkon/ Terrasse',
                },
                {
                    key: 'keller',
                    value: 'Keller',
                },
                {
                    key: 'stellplatz/garage',
                    value: 'Stellplatz/ Garage',
                },
                {
                    key: 'wohnungsbesichtigung_gutachter',
                    value: 'Wohnungsbesichtigung Gutachter',
                },
                {
                    key: 'geschäftsanfragen',
                    value: 'Geschäftsanfragen',
                },
                {
                    key: 'sonstiges',
                    value: 'Sonstiges',
                },
            ],
        },
        {
            key: 'vertragsverhältnis',
            value: 'Vertragsverhältnis',
            subValues: [
                {
                    key: 'mietvertrag',
                    value: 'Mietvertrag',
                },
                {
                    key: 'übergabeprotokoll',
                    value: 'Übergabeprotokoll',
                },
                {
                    key: 'sev_vertrag/_vollmacht',
                    value: 'SEV Vertrag/ Vollmacht',
                    limitedTo: ['investor'],
                },
                {
                    key: 'kündigungen',
                    value: 'Kündigungen',
                },
                {
                    key: 'sonstiges',
                    value: 'Sonstiges',
                },
            ],
        },
        {
            key: 'zahlungen',
            value: 'Zahlungen',
            subValues: [
                {
                    key: 'mietzahlungen',
                    value: 'Mietzahlungen',
                },
                {
                    key: 'mieterhöhung',
                    value: 'Mieterhöhung',
                },
                {
                    key: 'mietkaution',
                    value: 'Mietkaution',
                },
                {
                    key: 'kontoverbindung_ändern',
                    value: 'Kontoverbindung ändern',
                },
                {
                    key: 'rechnungen',
                    value: 'Rechnungen',
                },
                {
                    key: 'wohngeld',
                    value: 'Wohngeld',
                },
                {
                    key: 'mietausschüttung',
                    value: 'Mietausschüttung',
                    limitedTo: ['investor'],
                },
                {
                    key: 'sonstiges',
                    value: 'Sonstiges',
                },
            ],
        },
        {
            key: 'abrechnung',
            value: 'Abrechnung',
            subValues: [
                {
                    key: 'jahresabrechnung',
                    value: 'Jahresabrechnung',
                    limitedTo: ['investor'],
                },
                {
                    key: 'nebenkostenabrechung',
                    value: 'Nebenkostenabrechnung',
                },
                {
                    key: 'sonstiges',
                    value: 'Sonstiges',
                },
            ],
        },
        {
            key: 'hausverwaltung',
            value: 'Hausverwaltung',
            subValues: [
                { key: 'eigentümerversammlung', value: 'Eigentümerversammlung' },
                { key: 'wirtschaftsplan', value: 'Wirtschaftsplan' },
                { key: 'hausgeldabrechnung', value: 'Hausgeldabrechnung' },
                { key: 'gerätewartung', value: 'Gerätewartung' },
                { key: 'mieterwechsel', value: 'Mieterwechsel' },
                { key: 'verwalterwechsel', value: 'Verwalterwechsel' },
                { key: 'adressänderung', value: 'Adressänderung' },
                { key: 'objektbesichtigung', value: 'Objektbesichtigung' },
                { key: 'zählerstände', value: 'Zählerstände' },
                {
                    key: 'sonstiges',
                    value: 'Sonstiges',
                },
            ],
        },
        {
            key: 'sanierungen',
            value: 'Sanierungen',
            subValues: [
                { key: 'allgemeine_anfragen', value: 'Allgemeine Anfragen' },
                { key: 'renovierungsarbeiten_wohnung', value: 'Renovierungsarbeiten Wohnung' },
                { key: 'sanierungsmaßnahmen_gebäude', value: 'Sanierungsmaßnahmen Gebäude' },
                { key: 'schadensmeldung', value: 'Schadensmeldung' },
                {
                    key: 'sonstiges',
                    value: 'Sonstiges',
                },
            ],
        },
    ],
};
