import type { IClientConfig } from '@/types';
import { defineStore } from 'pinia';

export const useConfigStore = defineStore('config', () => {
    const config = ref<IClientConfig | null>(null);

    function setConfig(configObj: IClientConfig) {
        config.value = configObj;
    }

    return { config: computed(() => config.value), setConfig };
});
