<template>
    <li :style="`background-image: url(${backgroundImg.filename})`" class="feature-card" @click="navigate">
        <div class="feature-card__content">
            <h4>{{ headline }}</h4>
            <em v-for="{ label } in button" :key="label as string">
                {{ label }}
                <i class="fa-solid fa-angle-right fa-xs"></i>
            </em>
        </div>
    </li>
</template>

<script lang="ts" setup>
import type { SbBlokData, SbImage } from '@/components/StoryblokComponent.vue';
import { useRouter } from 'vue-router';

const props = defineProps<{ headline: string; text?: string; backgroundImg: SbImage; button?: SbBlokData[] }>();
const router = useRouter();

function navigate() {
    if (!props.button?.length) {
        return;
    }

    const [{ link }] = props.button as { link: string }[];
    if (link.startsWith('http') || link.startsWith('mailto')) {
        window.open(link, link.startsWith('http') ? '_blank' : undefined);
        return;
    }

    router.push(link);
}
</script>

<style lang="scss">
    .feature-card {
        @apply relative
        h-100
        cursor-pointer
        bg-cover
        bg-center;

        &__list {
            @apply grid
            gap-8;

            @screen laptop {
                @apply grid-cols-3
                gap-1;
            }

            @screen desktop {
                @apply gap-8;
            }
        }

        h4 {
            @apply mb-2
            text-current
            font-medium
            text-blue-900;
        }

        em {
            @apply font-semibold
            text-black;
        }

        .fa-angle-right {
            @apply ml-2.5;
        }

        &__content {
            @apply absolute
            inset-x-0
            bottom-0
            border-t
            border-white
            border-opacity-50
            bg-white
            bg-opacity-50
            px-5
            pb-8
            pt-6
            backdrop-blur-lg;
        }
    }
</style>
