import BlogPost from '@/components/BlogPost.vue';
import Button from '@/components/Button.vue';
import { FAQItem, FAQList, FAQSubTopic, FAQTopic } from '@/components/FAQ';
import FeatureCard from '@/components/FeatureCard.vue';
import FeatureList from '@/components/FeatureList.vue';
import Hero from '@/components/Hero.vue';
import ImageBlock from '@/components/ImageBlock.vue';
import ImageList from '@/components/ImageList.vue';
import ImageTextSection from '@/components/ImageTextSection.vue';
import Page from '@/components/Page.vue';
import PageSection from '@/components/PageSection.vue';
import RichText from '@/components/RichText.vue';
import SectionColumns from '@/components/SectionColumns.vue';
import SectionText from '@/components/SectionText.vue';
import StoryblokComponent from '@/components/StoryblokComponent.vue';
import Contact from '@/components/contact/Contact.vue';
import ContactsSection from '@/components/contact/ContactsSection.vue';
import ContactForm from '@/components/contactForm/index.vue';
import type { App } from 'vue';

const registerComponents = (app: App<Element>) => {
    app.component('contactForm', ContactForm);
    app.component('dynamicContactForm', ContactForm);
    app.component('faqItem', FAQItem);
    app.component('faqList', FAQList);
    app.component('faqSubTopic', FAQSubTopic);
    app.component('faqTopic', FAQTopic);
    app.component('StoryblokComponent', StoryblokComponent);
    app.component('Page', Page);
    app.component('PageSection', PageSection);
    app.component('FeatureList', FeatureList);
    app.component('RichText', RichText);
    app.component('FeatureCard', FeatureCard);

    app.component('Button', Button);
    app.component('HeroSection', Hero);
    app.component('ContactsSection', ContactsSection);
    app.component('Contact', Contact);
    app.component('SectionColumns', SectionColumns);
    app.component('ImageList', ImageList);
    app.component('ImageTextSection', ImageTextSection);
    app.component('ImageBlock', ImageBlock);
    app.component('SectionText', SectionText);
    app.component('BlogPost', BlogPost);
};
export default registerComponents;
