<template>
    <component :is="props.blok.component" v-bind="{ ...blok, ...$attrs }"></component>
</template>

<script lang="ts" setup>
import type { ISbComponentType } from 'storyblok-js-client';

export interface SbImage {
    id: number;
    alt: string;
    name: string;
    title: string;
    filename: string;
}

export interface SbBlokData extends ISbComponentType<string> {
    [index: string]: string | number | object | boolean | undefined;
}

export interface SbComponentProps {
    blok: SbBlokData;
}

const props = defineProps<SbComponentProps>();
const blok = computed(() => {
    const {
        blok: { _uid, component, ...blok },
    } = props;
    return blok;
});
</script>
