import { getAddressAutocomplete } from '@/api/here.api';
import { useQuery } from '@tanstack/vue-query';

export const useGetAddressAutocomplete = (queryOrRef: string | Ref<string>) => {
    const query = computed(() => (isRef(queryOrRef) ? queryOrRef.value : queryOrRef));
    return useQuery(
        ['addressAutocomplete', query],
        async () => {
            if (!query.value || query.value.length < 3) {
                return [];
            }
            const { data } = await getAddressAutocomplete(query.value);
            return data;
        },
        { enabled: false },
    );
};
