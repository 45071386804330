import type { InvestmentData } from '@condo/propx/sdk';
import { defineStore, storeToRefs } from 'pinia';

export const useInvestmentsStore = defineStore('investments', () => {
    const investments = ref<InvestmentData[]>([]);

    function setInvestments(data: InvestmentData[]) {
        investments.value = data;
    }

    return { investments, setInvestments };
});

export const useSelectedInvestmentStore = defineStore('selectedInvestment', () => {
    const investmentsStore = useInvestmentsStore();
    const { investments } = storeToRefs(investmentsStore);
    const initializeInvestorInfo = computed<InvestmentData>(() => investments.value[0]);

    const investment = ref<InvestmentData | null>(initializeInvestorInfo.value ?? null);

    function setInvestment(data: InvestmentData) {
        investment.value = data;
    }

    return { investment, setInvestment };
});
