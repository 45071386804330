import type { Location } from '@/components/autocomplete/types';
import { isNil } from 'lodash-es';
import type { ComposerTranslation } from 'vue-i18n';

type Validator<T = any> = (opts: { field: string; required: boolean }, value: T) => boolean | Error;

export const combineValidators = (...validators: Array<Validator>) => {
    return (opts: { field: string; required: boolean }, value: any) => {
        for (const validator of validators) {
            const result = validator(opts, value);
            if (result !== true) {
                return result;
            }
        }

        return true;
    };
};

export const existsValidator =
    (t: ComposerTranslation): Validator<string> =>
    ({ required }, value) => {
        if (required && (isNil(value) || value === '')) {
            return new Error(t('errors.requiredField'));
        }
        return true;
    };

export const phoneNumberValidator =
    (t: ComposerTranslation): Validator<string> =>
    ({ required }, value) => {
        const isValid = String(value)
            .toLowerCase()
            .match(/^(?:\+49\s?|0)?[1-9]\d{1,3}\s?(\d{1,8}|\(?\d{1,4}\)?\s?\d{1,4}(\s?-?\s?\d{1,4})*)$/);

        if ((required || value) && !isValid) {
            return new Error(t('errors.invalidFormat'));
        }

        return true;
    };

export const addressValidator = ({ requiredFields }: { requiredFields: Array<keyof Location> }, value: Location) => {
    for (const requiredField of requiredFields) {
        if (!value[requiredField]) {
            return new Error(`${requiredField} is required`);
        }
    }

    return true;
};

export const emailValidator =
    (t: ComposerTranslation): Validator<string> =>
    ({ required }, value) => {
        const isValid = String(value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );

        if ((required || !isNil(value)) && !isValid) {
            return new Error(t('errors.invalidFormat'));
        }

        return true;
    };

export const numberBetweenValidator =
    (
        fromYear: Ref<number> | number,
        toYear: Ref<number> | number,
        {
            required,
        }: {
            required: boolean;
        } = { required: true },
    ) =>
    ({ field }: { field: string }, value: string) => {
        const [from, to] = [unref(fromYear), unref(toYear)];

        if (required && (isNil(value) || value === '')) {
            return new Error(`${field} is required to be a number between ${from} and ${to}`);
        }

        const shouldValidate = required || (!isNil(value) && value !== '');
        if (shouldValidate && !(Number(value) >= from && Number(value) <= to)) {
            return new Error(`${field} is required to be a number between ${from} and ${to}`);
        }

        return true;
    };

export const isTrueValidator = ({ field }: { field: boolean }, value: boolean) => {
    if (value === true) {
        return true;
    }

    return new Error(`${field} is required to be a true`);
};
