import { authGuard } from '@auth0/auth0-vue';
import { ContactType } from '@condo/hubspot/types';
import type { App } from 'vue';
import { type RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import('@/layouts/LayoutPublic.vue'),
        children: [
            {
                path: '/',
                component: () => import('@/pages/CmsPage.vue'),
                name: 'homepage',
            },
            {
                path: '/impressum',
                component: () => import('@/pages/LegalPage.vue'),
                name: 'imprint',
            },
            {
                path: '/datenschutz',
                component: () => import('@/pages/LegalPage.vue'),
                name: 'data-protection',
            },
            {
                path: '/about-us',
                component: () => import('@/pages/AboutUsPage.vue'),
                name: 'about-us',
            },
            {
                path: '/blog',
                component: () => import('@/pages/BlogPage.vue'),
                name: 'blog',
            },
            {
                path: '/blog/:slug',
                component: () => import('@/pages/BlogPostPage.vue'),
                name: 'blog-post',
            },
            {
                path: '/faq/verwalter',
                component: () => import('@/pages/CmsPage.vue'),
                name: 'faq/verwalter',
                meta: {
                    customerType: ContactType.WEG,
                },
            },
            {
                path: '/faq/eigentuemer',
                component: () => import('@/pages/CmsPage.vue'),
                name: 'faq/eigentuemer',
                meta: {
                    customerType: ContactType.Investor,
                },
            },
            {
                path: '/contact',
                component: () => import('@/pages/CmsPage.vue'),
                name: 'contact',
            },
            {
                path: ':pathMatch(.*)*',
                component: () => import('@/pages/404Page.vue'),
                name: '404',
            },
            {
                path: '/library',
                component: () => import('@/pages/Library.vue'),
                name: 'library',
            },
        ],
    },
    {
        path: '/dashboard',
        component: () => import('@/layouts/LayoutPrivate.vue'),
        name: 'dashboard',
        beforeEnter: [authGuard],
        props: route => ({
            showBackBtn: route.name !== 'dashboard/home',
        }),
        children: [
            {
                path: '',
                component: () => import('@/pages/portal/DashboardPage.vue'),
                name: 'dashboard/home',
            },
            {
                path: 'documents',
                component: () => import('@/pages/portal/DocumentsPage.vue'),
                name: 'dashboard/documents',
            },
            {
                path: 'financials',
                component: () => import('@/pages/portal/FinancialDataPage/FinancialDataPage.vue'),
                name: 'dashboard/financials',
                children: [
                    {
                        path: 'payouts',
                        component: () => import('@/pages/portal/FinancialDataPage/PayoutsListPage.vue'),
                        name: 'dashboard/financials/payouts',
                    },
                    {
                        path: 'incomes-expenses',
                        component: () => import('@/pages/portal/FinancialDataPage/IncomesExpensesPage.vue'),
                        name: 'dashboard/financials/incomesExpenses',
                    },
                ],
            },
            {
                path: 'profile',
                component: () => import('@/pages/portal/ProfilePage.vue'),
                name: 'dashboard/profile',
            },
            {
                path: 'assets',
                component: () => import('@/pages/portal/AssetDetailsPage.vue'),
                name: 'dashboard/assets',
            },
            {
                path: 'contact',
                component: () => import('@/pages/portal/ContactPage.vue'),
                name: 'dashboard/contact',
                meta: {
                    customerType: ContactType.Investor,
                },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            };
        }

        return { top: 0 };
    },
});

export function setupRouter(app: App) {
    app.use(router);
}

export default router;
