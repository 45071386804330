<template>
    <article>
        <h2 :id="titleID">{{ props.title }}</h2>
        <div v-for="q in props.subTopics" :key="q._uid">
            <StoryblokComponent :blok="q" />
        </div>
    </article>
</template>

<script lang="ts" setup>
import { kebabCase } from 'lodash-es';

type Props = {
    title: string;
    subTopics: any[];
};
const props = defineProps<Props>();
const titleID = computed(() => kebabCase(props.title));
</script>
