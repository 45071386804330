<template>
    <div :class="`px-6 laptop:px-20 py-10 laptop:py-16 rounded-2xl ${bgClass}`">
        <h2 :class="`${textAlign[alignment]}`">{{ headline }}</h2>
        <RichText v-if="text" :text="text" :class="`${textAlign[alignment]}`" />
        <div v-if="button?.length" class="pt-8 text-center">
            <StoryblokComponent v-for="blok in button" :key="blok._uid" :blok="blok" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { SbBlokData } from '@/components/StoryblokComponent.vue';
import type { ISbRichtext } from 'storyblok-js-client';

const textAlign = {
    left: 'text-left',
    center: 'text-center',
};

const props = defineProps<{
    headline: string;
    lead?: string;
    text: ISbRichtext;
    button?: SbBlokData[];
    alignment: 'left' | 'center';
    backgroundColor: 'white' | 'light';
}>();

const bgClass = computed(() => (props.backgroundColor === 'white' ? 'bg-white' : 'bg-light'));
</script>
