<template>
    <section class="hero">
        <div :class="`${className} ${detachedOnMobile ? 'hero__banner--detached' : ''}`" class="hero__banner">
            <div
                :class="`hero__image ${props.blur ? 'hero__image__blur' : ''}`"
                :style="`background-image: url(${backgroundImg.filename}); background-position: ${bgPosition};`"
            ></div>
            <div :class="`${!className ? 'py-8 laptop:pt-28 items-center' : ''} ${detachedOnMobile ? 'hidden tablet:block' : ''}`" class="z-8 container">
                <div class="pr-4 laptop:w-2/3 laptop:pr-0 desktop:w-1/2">
                    <h2>{{ headline }}</h2>
                    <h5>{{ text }}</h5>
                </div>
            </div>
        </div>
        <div v-if="props.detachedOnMobile" class="container mt-8 tablet:hidden">
            <h1>{{ props.headline }}</h1>
            <h5>{{ props.text }}</h5>
        </div>
    </section>
</template>

<script lang="ts" setup>
import type { SbImage } from '@/components/StoryblokComponent.vue';

const props = defineProps<{
    headline: string;
    text: string;
    className?: string;
    backgroundImg: SbImage;
    detachedOnMobile?: boolean;
    blur?: boolean;
    positionX?: 'left' | 'center' | 'right';
    positionY?: 'top' | 'center' | 'bottom';
}>();

const bgPosition = computed(() => `${props.positionX || 'center'} ${props.positionY || 'bottom'}`);
</script>

<style lang="scss" scoped>
    .hero {
        &__banner {
            @apply flex
        min-h-[calc(100vh-theme('space[20]'))]
        relative;

            &--detached {
                @apply min-h-[40vh];

                @screen tablet {
                    @apply min-h-[calc(100vh-theme('space[20]'))];
                }
            }

            .hero__image__blur {
                &::before {
                    content: '';
                    @apply absolute
                top-0
                right-0
                bottom-0
                left-0
                bg-gray-200
                opacity-50;
                }
            }

            .hero__image {
                @apply absolute
            top-0
            right-0
            bottom-0
            left-0
            bg-cover;
                z-index: 0;
            }
        }

        h1,
        h2,
        h5 {
            @apply whitespace-pre-line
        laptop:drop-shadow-custom
        relative;
            z-index: 1;
        }

        h5 {
            @apply mt-6
        leading-inherit
        text-black;
        }
    }
</style>
