import router from '@/router';
import type { IClientConfig } from '@/types';
import { useAuth0 } from '@auth0/auth0-vue';
import { createGtm, useGtm } from '@gtm-support/vue-gtm';
import type { App } from 'vue';
import { useCookies } from 'vue3-cookies';

export function setupGTM(app: App, config: IClientConfig) {
    if (config?.gtmId) {
        app.use(
            createGtm({
                id: config?.gtmId,
                defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
                compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
                //@ts-ignore
                enabled: window.Cookiebot?.consent?.statistics ?? false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
                loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
                vueRouter: router as any, // Pass the router instance to automatically sync with router (optional)
                vueRouterAdditionalEventData: () => {
                    const { user } = useAuth0();
                    const { cookies } = useCookies();
                    const cid = cookies.get('_ga')?.replace('GA1.1.', '');

                    return { user_id: user.value?.email ?? cid };
                },
                ignoredViews: [], // Don't trigger events for specified router names (optional)
                trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
            }),
        );
        window.addEventListener(
            'CookiebotOnAccept',
            () => {
                //@ts-ignore
                if (window.Cookiebot?.consent?.statistics) {
                    const gtm = useGtm();
                    gtm?.enable(true);
                }
            },
            false,
        );
    }
}
