<template>
    <n-form-item :label="title" :path="path" label-style="font-weight: 500">
        <n-select :options="options" :path="path" :v-model="path" :value="value" @update:value="emit('input', $event)" />
    </n-form-item>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type Props = {
    options: { label: string; value: string }[];
    title: string;
    value: string;
    path: string;
};
type Emits = (ev: 'input', any) => void;

const emit = defineEmits<Emits>();

const props = defineProps<Props>();
onMounted(() => {
    emit('input', props.value || props.options[0]?.value);
});
</script>
