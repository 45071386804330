import type { IClientConfig } from '@/types';
import { type Auth0VueClient, createAuth0 } from '@auth0/auth0-vue';
import type { App } from 'vue';

export let auth0Client: Auth0VueClient = null!;

export function exposeAuth0() {
    return {
        async install(app: App) {
            auth0Client = app.config.globalProperties['$auth0'];
        },
    };
}

/**
 * Request new or get cached JWT access token
 * @returns {Promise<string>}
 */
export async function getBearerToken(ignoreCache = false): Promise<string | null> {
    if (auth0Client.isAuthenticated.value) {
        try {
            return auth0Client.getAccessTokenSilently({
                authorizationParams: {
                    scope: 'openid profile email',
                },
                cacheMode: ignoreCache ? 'off' : 'on',
            });
        } catch (_) {
            /* empty */
        }
    }

    return null;
}

export function setupAuth0(app: App, config: IClientConfig) {
    app.use(
        createAuth0({
            domain: config.auth0.domain,
            clientId: config.auth0.clientId,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/dashboard`,
                audience: config.auth0.audience,
                scope: 'openid profile email',
            },
        }),
    ).use(exposeAuth0());
}
