<template>
    <div
        @mousemove="props.onMousemove?.($event)"
        @mouseenter="props.onMouseenter?.($event)"
        @click="props.onClick?.($event)"
        v-bind:class="{ 'autocomplete-option--hovered': props.selected }"
    >
        <span v-if="props.highlights?.length" class="autocomplete-option--text">
            <span v-for="highlight of props.highlights" :key="highlight.text">
                <b class="autocomplete-option--highlight" v-if="highlight.highlight">{{ highlight.text }}</b>
                <span v-else>{{ highlight.text }}</span>
            </span>
        </span>
        <span v-else class="autocomplete-option--text">{{ props.label }}</span>
    </div>
</template>

<script lang="ts" setup>
interface Props {
    label: string;
    highlights: { text: string; highlight: boolean }[];
    selected?: boolean;
    onClick?: () => void;
    onMouseenter?: () => void;
    onMousemove?: () => void;
}

const props = withDefaults(defineProps<Props>(), { selected: false });
</script>

<style lang="scss" scoped>
    .autocomplete-option--hovered {
        background-color: white;
    }

    .autocomplete-option--text {
        margin: 2px 2px;
    }

    .autocomplete-option--highlight {
        font-weight: 700;
        background-color: lightyellow;
        padding: 5px 2px;
    }
</style>
