<template>
    <section v-if="items?.length" :class="`${pt} ${pb} ${pl} ${pr} ${className}`">
        <div class="page-section">
            <StoryblokComponent v-for="blok in items" :key="blok._uid" :blok="blok" />
        </div>
    </section>
</template>

<script lang="ts" setup>
import StoryblokComponent, { type SbBlokData } from '@/components/StoryblokComponent.vue';

interface Props {
    spacingTop?: string;
    spacingBottom?: string;
    spacingLeft?: string;
    spacingRight?: string;
    className?: string;
    items: SbBlokData[];
}

const props = defineProps<Props>();

const pt = props.spacingTop ? `pt-${props.spacingTop}` : 'pt-12 laptop:pt-24';
const pb = props.spacingBottom ? `pb-${props.spacingBottom}` : 'pb-12 laptop:pb-24';
const pl = props.spacingLeft ? `pl-${props.spacingLeft}` : '';
const pr = props.spacingRight ? `pr-${props.spacingRight}` : '';
</script>

<style lang="scss">
    .page-section {
        @apply container;

        h2,
        h5 {
            @apply mb-4;

            @screen laptop {
                @apply mb-6;
            }
        }

        h5 {
            @apply text-current;
        }
    }

    .our-services {
        @screen laptop {
            @apply text-lg;
        }

        .section-columns {
            @apply mt-10
    items-center;
        }

        h4 {
            @apply mb-2
    font-medium;
        }

        .rich-text {
            @apply mb-8;

            &:last-child {
                @apply mb-0;
            }

            @screen laptop {
                @apply mb-12;
            }
        }

        img {
            @apply w-full;

            @screen tv {
                @apply mx-auto
      w-10/12;
            }
        }
    }

    .what-we-do {
        @screen laptop {
            @apply text-lg;
        }

        img {
            @apply mt-12
    w-full;

            @screen tv {
                @apply mt-16;
            }
        }
    }

    .our-cities {
        @screen laptop {
            @apply text-lg;
        }
    }
</style>
