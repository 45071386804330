import { getBearerToken } from '@/plugins/auth0';
import type { IClientConfig } from '@/types';
import { type TRPCClient, createTRPCClient } from '@condo/trpc';
import { v4 as uuid } from 'uuid';
import type { AppRouter } from '../../types';

export let trpc: TRPCClient<AppRouter>;

export function setupTRPC(config: IClientConfig) {
    trpc = createTRPCClient<AppRouter>({
        log: config.environmentName !== 'prod',
        baseUrl: config.apiUrl,
        async headers() {
            const streamId = uuid();
            const headers: { [key: string]: string } = { 'x-stream-id': streamId };

            const token = await getBearerToken();
            if (token) {
                headers.Authorization = `Bearer ${token}`;
            }

            return headers;
        },
    });
}
