<template>
    <div class="flex flex-row items-center gap-3">
        <span>
            <Icon :icon="props.icon" size="xl" />
        </span>
        <h2>{{ props.title }}</h2>
    </div>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';

const props = defineProps<{ icon: string; title: string }>();
</script>
