<template>
    <div v-if="text" :class="className" v-html="htmlWithBr"></div>
</template>

<script lang="ts" setup>
import { type ISbRichtext, RichtextResolver } from 'storyblok-js-client';

const props = defineProps<{ text: ISbRichtext; className?: string }>();

const resolver = new RichtextResolver();
const html = resolver.render(props.text);
const htmlWithBr = html.replaceAll('<p></p>', '<br/>');
</script>
