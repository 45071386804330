import type { App } from 'vue';
import { createI18n } from 'vue-i18n';

import de from './locales/de';

const locales = { de };
type LocaleKey = keyof typeof locales;
const defaultLocale: LocaleKey = 'de';

const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: 'de',
    messages: locales,
    warnHtmlInMessage: 'off',
    legacy: false,
});

export function setupI18n(app: App) {
    app.use(i18n);
}
