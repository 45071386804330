import { useConfigStore } from '@/store';
import axios from 'axios';

export const getAddressAutocomplete = async (query: string): Promise<any> => {
    const configStore = useConfigStore();
    const { cacheKey, cacheUrl } = configStore.config!.here;

    const params = {
        q: query,
        countryCode: 'countryCode:DEU',
        lang: 'de',
    };

    const { data } = await axios.get(`${cacheUrl}/here/autocomplete`, {
        params: params,
        headers: { Authorization: `Bearer ${cacheKey}` },
    });

    return data;
};
