import '@condo/ui/build/style.css';
import './styles/tailwind.css';

import { setupI18n } from '@/i18s';
import { setupAuth0 } from '@/plugins/auth0';
import { setupGTM } from '@/plugins/gtm';
import { setupStore } from '@/plugins/pinia-store';
import { setupTRPC } from '@/plugins/trpc';
import { setupVueQuery } from '@/plugins/vue-query';
import router, { setupRouter } from '@/router';
import { useConfigStore } from '@/store';
import type { IClientConfig } from '@/types';
import { createApp } from 'vue';

import { initHttpClients } from '@/plugins/api-client';
import isEmpty from 'lodash-es/isEmpty';
import isString from 'lodash-es/isString';
import kebabCase from 'lodash-es/kebabCase';
import registerComponents from './registerComponents';

const initApp = async (config: IClientConfig) => {
    const App = defineAsyncComponent(() => import('./App.vue'));
    const app = createApp(App);
    registerComponents(app);
    setupRouter(app);
    setupVueQuery(app);
    setupI18n(app);
    setupStore(app);
    setupTRPC(config);
    setupGTM(app, config);
    setupAuth0(app, config);
    initHttpClients(config);

    app.directive('id', (el, binding) => {
        const pageName = router.currentRoute.value.name;
        el.id = `${isString(pageName) && !isEmpty(pageName) ? `${pageName}-page.` : ''}${kebabCase(binding.value)}`;
    });

    const configStore = useConfigStore();
    configStore.setConfig(config);
    return app;
};

(async () => {
    const response = await fetch('/config');
    const configObj: IClientConfig = await response.json();
    const meta = document.createElement('meta');
    meta.name = 'naive-ui-style';
    document.head.appendChild(meta);

    const app = await initApp(configObj);

    app.mount(document.body);
})();
