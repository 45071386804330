<template>
    <condo-button :class="className" :icon-left="iconLeft" :icon-right="iconRight" :text="variant === 'link'" :type="`is-${buttonColor}`" @click="navigate" :v-id="buttonId"
        >{{ label }}
    </condo-button>
</template>

<script lang="ts" setup>
import { CondoButton } from '@condo/ui';
import { useRouter } from 'vue-router';

const props = withDefaults(
    defineProps<{
        link: string;
        label: string;
        className?: string;
        variant: 'link' | 'button';
        buttonColor: 'primary' | 'default';
        iconLeft?: string;
        iconRight?: string;
        buttonId: string;
    }>(),
    { buttonColor: 'primary', variant: 'button' },
);
const router = useRouter();

function navigate() {
    if (props.link.startsWith('http') || props.link.startsWith('mailto')) {
        window.open(props.link, props.link.startsWith('http') ? '_blank' : undefined);
        return;
    }
    router.push(props.link);
}
</script>

<style lang="scss" scoped>
    .n-button__content > span {
        @apply ml-0;
    }
</style>
