<template>
    <n-auto-complete
        v-model:value="valueRef"
        :input-props="{ autocomplete: 'disabled' }"
        clearable
        :disabled="disabled"
        :loading="loading"
        :options="options"
        @blur="onBlur"
        @update:value="onUpdateAutocompleteInput"
        :render-option="renderAutocompleteOption"
        :placeholder="props.placeholder"
        :required="props.required"
        ref="inputRef"
    />
    <span v-if="hasError && props.errorMessage" class="text-error">{{ props.errorMessage }}</span>
</template>

<script setup lang="ts">
import { useGetAddressAutocomplete } from '@/hooks/here.hooks';
import { debounce } from 'lodash';
import type { SelectOption } from 'naive-ui';
import { type VueElement, h, ref, watch } from 'vue';

import { addressFormatter } from '@condo/formatters';

import AddressAutocompleteOption from './AddressAutocompleteOption.vue';
import type { Emits, ILabelPart, ILocationOptions, Location } from './types';

interface Props {
    modelValue: Location;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
    errorMessage?: string;
}

const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    required: false,
});
const emit = defineEmits<Emits>();

const EMPTY_FORM_LOCATION = {
    street: '',
    houseNumber: '',
    city: '',
    zipCode: '',
};

const renderAutocompleteOption = ({ option, node }: SelectOption & { option: ILocationOptions }) => {
    return h(AddressAutocompleteOption, {
        label: option.label,
        highlights: option.highlights ?? [],
        selected: (node as any).props.class?.includes('n-base-select-option--pending') ?? false,
        onClick: (ev: Event) => (node as any).props.onClick(ev),
        onMouseenter: (ev: Event) => (node as any).props.onMouseenter(ev),
        onMousemove: (ev: Event) => (node as any).props.onMousemove(ev),
    });
};

const inputRef = ref<VueElement>();
const valueRef = ref('');
const formState = ref<Partial<Location>>(props.modelValue);
const options = ref<ILocationOptions[]>([]);
const { data, isError, isFetching, refetch } = useGetAddressAutocomplete(valueRef);
const fetchLocations = debounce(refetch, 500);
const loading = ref(isFetching);
const hasError = ref(isError.value);

const onBlur = () => {
    if (options.value?.length) {
        emit('update:modelValue', options.value[0].value);
    }
};

const updateInputValueFromLocation = (location?: Partial<Location>) => {
    if (!location) {
        valueRef.value = '';
        return;
    }
    const { street, houseNumber, city, zipcode } = location;
    const formattedAddress = addressFormatter({ street, houseNumber, city, zipcode });

    if (formattedAddress.trim()) {
        valueRef.value = formattedAddress;
    } else {
        valueRef.value = '';
    }
};

const onUpdateAutocompleteInput = (value: string | null) => {
    hasError.value = false;
    valueRef.value = value as string;
    if (!value) {
        formState.value = { ...EMPTY_FORM_LOCATION };
    }

    fetchLocations();
};

watch(
    formState,
    next => {
        emit('update:modelValue', next);
        updateInputValueFromLocation(next);
    },
    { deep: true, immediate: true },
);

watch(data, locations => {
    if (locations) {
        options.value = locations.map((o: { label: string; location: Location; highlights: ILabelPart[] }) => ({
            label: o.label,
            value: o.location,
            highlights: o.highlights?.map(h => ({ ...h, highlight: (h as any).highlight === 'true' })),
        }));
    }
});

watch(
    () => props.modelValue,
    next => {
        formState.value = next ?? { ...EMPTY_FORM_LOCATION };
        updateInputValueFromLocation(next);
    },
    { deep: true },
);
</script>
